import React, { useState } from "react";
import './styles.css';
import cone from "./media/traffic-cone.png"

function App() {

  const [rotationAngle, setRotationAngle] = useState(0);

  const handeClick = () => {
    setRotationAngle(rotationAngle => rotationAngle + 360);
    console.log(rotationAngle);
  }

  return (
    <div className="App">
      <h5 className="construction" id="clickMe">Click me!</h5>
      <img src={cone} alt="cone" width="200" height="200" className="construction" id="coneImage" style={{ transform: `rotate(${rotationAngle}deg)`, transition: 'transform 1s ease' }} onClick={handeClick}></img>
      <h1 className="construction" id="text">Site Under Construction</h1>
    </div>
  );
}

export default App;
